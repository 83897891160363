.navbar {
	position: fixed;
	top: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 20px;
	width: 100%;
	height: 50px;
	background-color: black;
	z-index: 99;
	transition: background-color 2s;
}
.logo h2 {
	color: #f00;
	transition: transfrom 0.4s;
}
.logo:hover h2 {
	color: rgb(204, 18, 18);
	cursor: pointer;
	transform: scale(1.1);
}
.search {
	width: 50px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}
.search:hover {
	cursor: pointer;
}
.search svg {
	width: 30px;
	height: 30px;
	color: #fff;
}
.transparent {
	background-color: transparent;
}
